<template>
    <Modal id="preferred-facility" @close="close">
        <template #modal-title>
            <h3>Change Facility</h3>
        </template>

        <template #modal-body>
            <div v-if="facilities" class="form-group my-4">
                <select v-model="preferredFacilityId" class="custom-select">
                    <option
                        v-for="facility in facilities"
                        :key="facility.id"
                        :class="{
                            'text-success': facility.fac_coming_soon > 0,
                        }"
                        :value="facility.id"
                    >
                        {{ facility.name }}
                        {{ facility.fac_coming_soon ? ' (COMING SOON!)' : '' }}
                    </option>
                </select>
            </div>
            <div v-else>
                <p class="text-center">Loading...</p>
            </div>
        </template>
        <template #footer>
            <button
                :disabled="allowAdding"
                class="btn btn-sm btn-secondary"
                @click="changeFacility"
            >
                Submit
            </button>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/General/Modal';
import { mapActions, mapState } from 'vuex';
export default {
    components: {
        Modal,
    },
    props: {
        disableClosing: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    data() {
        return {
            preferredFacilityId: null,
            isClosingDisabled: null,
        };
    },
    computed: {
        allowAdding() {
            return !this.preferredFacilityId;
        },
        ...mapState('Facility', ['facilities']),
        ...mapState('User', ['profile']),
    },
    mounted() {
        this.preferredFacilityId = this.profile.preferred_facility_id;
        this.isClosingDisabled = this.disableClosing;
    },
    created() {
        if (!this.profile) {
            this.userRequest();
        }
        if (!this.facilities) {
            this.facilitiesRequest();
        }
    },
    methods: {
        close() {
            if (!this.isClosingDisabled) {
                this.$emit('close');
            }
        },
        changeFacility() {
            if (this.preferredFacilityId) {
                this.facilityUpdateRequest(this.preferredFacilityId);
                this.isClosingDisabled = false;
                this.close();
            }
        },
        ...mapActions('Facility', [
            'facilitiesRequest',
            'facilityUpdateRequest',
        ]),
        ...mapActions('User', ['userRequest']),
    },
};
</script>
<style lang="scss" scoped>
.form-row,
.form-group,
.custom-control {
    label {
        font-size: 20px;
    }
}

#preferred-facility {
    font-family: 'Trebuchet MS', sans-serif;
}
</style>
